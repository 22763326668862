import { useFirebaseValue } from '/machinery/useFirebaseDatabase'
import { statements } from '/data/statements'
import { equalTo, orderByChild, query, ref } from 'firebase/database'

export function useUserSelectedStatements() {
  const { data, isFetching } = useFirebaseValue({
    reference: ({ user, database }) => query(
      ref(database, `/users/${user.uid}/statements`),
      orderByChild('selected'),
      equalTo(true)
    ),
  })

  return {
    isFetching,
    data: data ? mapStatementsToTheSelectedEntries(data) : {},
  }

  function mapStatementsToTheSelectedEntries(data) {
    return Object.keys(data).reduce(
      (result, key) => {
        result[key] = { ...statements[key], form: data[key].form || null, selected: data[key].selected || false, subjectToDiscus: data[key].subjectToDiscus || false }
        return result
      },
      {}
    )
  }
}
